import React from "react"
import Blog from "../components/communityBlogs"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import languages from "../utils/enums/languages"

export default function BlogTemplate({ pageContext, location }) {
  const data = pageContext.alldata.frontmatter
  const blogUrl = `community-news/${data.title
    .replace(/\s/g, "-")
    .replace(/[^\w-]/g, "")
    .toLowerCase()}`

  return (
    data && (
      <Layout
        pageContext={pageContext}
        secondary
        location={location}
        SEO={() => (
          <SEO
            title={data.page_title}
            description={data.meta_description}
            languages={[
              ...languages.map(l => {
                return {
                  hrefLang: l || 'en',
                  href: `${process.env.GATSBY_SITE_URL}${
                    l ? "/" + l + "/" : "/"
                  }${blogUrl}/`,
                }
              }),
              {
                hrefLang: "x-default",
                href: `${process.env.GATSBY_SITE_URL}/${blogUrl}/`,
              },
            ]}
          />
        )}
      >
        <Blog
          author_image={data.author_image}
          author_name={data.author_name}
          blog_image={data.blog_image}
          blog_post={data.blog_post}
          category={data.category}
          date={data.date}
          slug={data.slug}
          title={data.title}
          feature1={data.feature1}
          feature2={data.feature2}
        />
      </Layout>
    )
  )
}
