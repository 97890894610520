import * as React from "react"
import ReactMarkdown from "react-markdown"
import { navigate } from "gatsby"
// import Blog from "../community/blogs"
import backimg from "../../assets/images/icons/blogback.svg"
const RoasterPage = ({
  author_image,
  author_name,
  blog_image,
  blog_post,
  category,
  date,
  title,
  history,
}) => {
  // const nodes = useStaticQuery(
  //   graphql`
  //     query blogsdata {
  //       allMarkdownRemark {
  //         nodes {
  //           frontmatter {
  //             author_image
  //             author_name
  //             blog_image
  //             blog_post
  //             category
  //             date
  //             slug
  //             title
  //             page_title
  //             meta_description
  //             thumbnail_summary
  //             thumbnail_image
  //           }
  //         }
  //       }
  //     }
  //   `
  // )
  // const allData = nodes.allMarkdownRemark.nodes
  const renderers = {
    image: value => {
      return (
        <img
          title={value.title}
          alt={value.alt}
          src={require("../../../static/assets/" +
            value.src.split("/")[value.src.split("/").length - 1])}
        />
      )
    },
  }
  const dateInput = new Date(date)

  const assetLoader = require.context("../../../static/assets", true)
  const imageLoader = imgPath => assetLoader(`./${imgPath}`).default

  return (
    <>
      {
        <div className="consmer_blog">
          <div className="content">
            <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
              <div className="backbtn">
                <img src={backimg} alt="Back" />
                Back
              </div>
            </div>
            <div className="header-title">
              <h2 className="category-type-text">{category}</h2>
              <h1>{title}</h1>
            </div>
            <div className="author-detail">
              <img
                src={imageLoader(
                  author_image.split("/")[author_image.split("/").length - 1]
                )}
                alt={author_name || ""}
              />
              <div className="authorname">{author_name}</div>
              <div className="divider"></div>
              <div className="date">{dateInput?.toDateString()}</div>
            </div>
            <img
              src={imageLoader(
                blog_image.split("/")[blog_image.split("/").length - 1]
              )}
              alt="Blog img"
              className="main-page-check"
            />
            <div className="markdown-file">
              <ReactMarkdown renderers={renderers}>{blog_post}</ReactMarkdown>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default RoasterPage
